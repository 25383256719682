import type { Feature, MultiPolygon, Polygon } from "geojson";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import type { QueryOptions } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import {
  type MapboxMouseEvent,
  isMultiPolygonFeature,
  isPolygonFeature,
  isPointFeature,
  isLineStringFeature,
} from "../types";

export interface BurntAreaHazardReductionLayerBaseProps {
  layerId: string;
  layerUrl: string;
  maxzoom?: number;
  minzoom?: number;
  opacity?: number;
  queryOptions: QueryOptions;
}

type BurntAreaHazardReductionFeatureFields = {
  OBJECTID: number;
  ActivityName: string;
  ActivityType: string;
  LodgingAgencyNames: string;
  NumberOfPropertiesProtected: number;
  Reason: string;
  ResidualFuelLoad: string;
  Title: string;
  TotalTreatedAreaHa: number;
};

export const burntAreaHazardReductionOutfields = [
  "OBJECTID",
  "ActivityName",
  "ActivityType",
  "LodgingAgencyNames",
  "NumberOfPropertiesProtected",
  "Reason",
  "ResidualFuelLoad",
  "Title",
  "TotalTreatedAreaHa",
] as const satisfies readonly (keyof BurntAreaHazardReductionFeatureFields)[];

type BurntAreaHazardReductionFeature = Feature<
  Polygon | MultiPolygon,
  BurntAreaHazardReductionFeatureFields
>;

export type BurntAreaHazardReductionData = {
  objectId: number;
  activityName: string;
  activityType: string;
  lodgingAgencyNames: string;
  numberOfPropertiesProtected: number;
  reason: string;
  residualFuelLoad: string;
  title: string;
  totalTreatedAreaHa: number;
};

export const burntAreaHazardReductionLabels = {
  objectId: "Object Id",
  activityName: "Activity name",
  activityType: "Activity type",
  lodgingAgencyNames: "Agency names",
  numberOfPropertiesProtected: "Props. protected",
  reason: "Reason",
  residualFuelLoad: "Residual fuel load",
  title: "Title",
  totalTreatedAreaHa: "Treated area (HA)",
} as const satisfies Record<keyof BurntAreaHazardReductionData, string>;

export function getPropertiesFromFeature(
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): BurntAreaHazardReductionInteractionProperties | null {
  if (
    !isMultiPolygonFeature(feature) &&
    !isPolygonFeature(feature) &&
    !isLineStringFeature(feature) &&
    !isPointFeature(feature)
  ) {
    return null;
  }

  const { lngLat } = event;
  const burntAreaFeature = feature as BurntAreaHazardReductionFeature;

  return {
    data: {
      objectId: burntAreaFeature.properties?.OBJECTID,
      activityName: burntAreaFeature.properties?.ActivityName ?? "",
      activityType: burntAreaFeature.properties?.ActivityType ?? "",
      lodgingAgencyNames: burntAreaFeature.properties?.LodgingAgencyNames ?? "",
      numberOfPropertiesProtected:
        burntAreaFeature.properties?.NumberOfPropertiesProtected ?? 0,
      reason: burntAreaFeature.properties?.Reason ?? "",
      residualFuelLoad: burntAreaFeature.properties?.ResidualFuelLoad ?? "",
      title: burntAreaFeature.properties?.Title ?? "",
      totalTreatedAreaHa: burntAreaFeature.properties?.TotalTreatedAreaHa ?? 0,
    } as BurntAreaHazardReductionData,
    featureId: burntAreaFeature.properties?.OBJECTID,
    lngLat,
  };
}

export interface BurntAreaHazardReductionInteractionProperties
  extends FeatureInteractionProperties {
  data: BurntAreaHazardReductionData;
}

export type BurntAreaHazardReductionInteractionState =
  FeatureInteractionState<BurntAreaHazardReductionInteractionProperties>;
