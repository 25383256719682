import { FieldGrid } from "@app/design-system";
import styled from "styled-components";
import { EMDASH } from "../../../lib/strings";
import { formatNumber } from "../../../utils/formatNumber/formatNumber";
import {
  burntAreaHazardReductionLabels,
  type BurntAreaHazardReductionInteractionProperties,
} from "../../map/BurntAreaHazardReduction/utils";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export interface BurntAreaHazardReductionPopupDetailProps {
  properties: BurntAreaHazardReductionInteractionProperties;
}

const BurntAreaHazardReductionPopupDetail = ({
  properties,
}: BurntAreaHazardReductionPopupDetailProps) => {
  return (
    <StyledBody>
      <FieldGrid>
        <FieldGrid.Item label={`${burntAreaHazardReductionLabels.objectId}:`}>
          {properties.data.objectId !== undefined
            ? formatNumber(properties.data.objectId)
            : EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item
          label={`${burntAreaHazardReductionLabels.activityName}:`}
        >
          {properties.data.activityName || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item
          label={`${burntAreaHazardReductionLabels.activityType}:`}
        >
          {properties.data.activityType || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item
          label={`${burntAreaHazardReductionLabels.lodgingAgencyNames}:`}
        >
          {properties.data.lodgingAgencyNames || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item
          label={`${burntAreaHazardReductionLabels.numberOfPropertiesProtected}:`}
        >
          {properties.data.numberOfPropertiesProtected !== undefined
            ? formatNumber(properties.data.numberOfPropertiesProtected)
            : EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item label={`${burntAreaHazardReductionLabels.reason}:`}>
          {properties.data.reason || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item
          label={`${burntAreaHazardReductionLabels.residualFuelLoad}:`}
        >
          {properties.data.residualFuelLoad || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item label={`${burntAreaHazardReductionLabels.title}:`}>
          {properties.data.title || EMDASH}
        </FieldGrid.Item>
        <FieldGrid.Item
          label={`${burntAreaHazardReductionLabels.totalTreatedAreaHa}:`}
        >
          {properties.data.totalTreatedAreaHa !== undefined
            ? formatNumber(properties.data.totalTreatedAreaHa)
            : EMDASH}
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default BurntAreaHazardReductionPopupDetail;
