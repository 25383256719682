import type { ElementType } from "@kablamo/kerosene";
import { burntAreaHazardReductionLayer } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import BurntAreaHazardReductionPointLayer from "./BurntAreaHazardReductionPointLayer";
import BurntAreaHazardReductionPolygonLayer from "./BurntAreaHazardReductionPolygonLayer";
import {
  burntAreaHazardReductionOutfields,
  type BurntAreaHazardReductionLayerBaseProps,
} from "./utils";

type SubLayerConfig = {
  component: React.ComponentType<BurntAreaHazardReductionLayerBaseProps>;
  maxzoom?: number;
  minzoom?: number;
  url: string;
};

// The Burn Hazard Reduction data is actually made up of 4 different GIS layers
// under the hood. The overview layer is only viewable at a high zoom level,
// while the other 3 offer more detail are only visible when sufficiently
// zoomed in. They are controlled with a single toggle in Athena by design.
// https://gis-guardian.dev.apps.rfs.nsw.gov.au/arcgis/rest/services/Reference/GuardianBurnActivities/MapServer
const hazardReductionGisLayerTypes = [
  "overview",
  "point",
  "line",
  "polygon",
] as const;

type HazardReductionGisLayerTypes = ElementType<
  typeof hazardReductionGisLayerTypes
>;

const gisLayerTypeConfigMap: Record<
  HazardReductionGisLayerTypes,
  SubLayerConfig
> = {
  overview: {
    component: BurntAreaHazardReductionPointLayer,
    maxzoom: 8,
    url: `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/GuardianBurnActivities/MapServer/171/query`,
  },
  point: {
    component: BurntAreaHazardReductionPointLayer,
    minzoom: 8,
    url: `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/GuardianBurnActivities/MapServer/172/query`,
  },
  line: {
    component: BurntAreaHazardReductionPolygonLayer,
    minzoom: 8,
    url: `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/GuardianBurnActivities/MapServer/173/query`,
  },
  polygon: {
    component: BurntAreaHazardReductionPolygonLayer,
    minzoom: 8,
    url: `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/GuardianBurnActivities/MapServer/174/query`,
  },
};

const BurntAreaHazardReduction = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(burntAreaHazardReductionLayer.id)) {
    return null;
  }

  const state = getLayerState(burntAreaHazardReductionLayer.id);

  return hazardReductionGisLayerTypes.map((type) => {
    const {
      component: Component,
      maxzoom,
      minzoom,
      url,
    } = gisLayerTypeConfigMap[type];

    return (
      <Component
        key={type}
        layerId={`${burntAreaHazardReductionLayer.id}_${type}`}
        layerUrl={url}
        maxzoom={maxzoom}
        minzoom={minzoom}
        opacity={state?.opacity}
        queryOptions={{
          queryParams: {
            outFields: burntAreaHazardReductionOutfields.join(","),
          },
        }}
      />
    );
  });
};

export default BurntAreaHazardReduction;
